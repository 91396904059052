import React from 'react';
import PropTypes from 'prop-types';
import NoResults from './NoResults';
import FilterMenu from './FilterMenu';
import CategoryMenu from './CategoryMenu';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';
import definitionCategoryShape from 'shapes/definitionCategoryShape';
import definitionShape from 'shapes/definitionShape';

Menus.propTypes = {
  categoryFilters: PropTypes.shape({
    categoryUids: PropTypes.array
  }),
  conceptCategories: definitionCategoryShape,
  filterableCategories: definitionCategoryShape,
  handleChange: PropTypes.func.isRequired,
  isMobileMenu: PropTypes.bool,
  termsByCategory: PropTypes.arrayOf(definitionShape),
};

function Menus({
  categoryFilters,
  categories,
  handleChange,
  isMobileMenu = false,
  termsByCategory,
}) {
  const { searchResults, searchTerm } = useKeyConceptsContext();
  const categoryItems = categories?.items ?? [];

  // Filtering out categories to show based on which checkboxes are selected
  const currentCategoryItems = categoryItems.filter((item) => {
    return categoryFilters?.categoryUids?.some((result) => {
      return result === item.uid;
    });
  });

  // If the search completed, filter to display only the categories from
  // the full list that also returned in the search results
  let searchCategoryItems;
  if (searchTerm) {
    searchCategoryItems = currentCategoryItems.filter((item) => {
      return searchResults?.some((result) => {
        return result.category === item.uid;
      });
    });
  }

  // If search completed and the search term is not null, display the categories
  // from the search; otherwise show all the categories
  const categoriesToShow = searchTerm ? searchCategoryItems : currentCategoryItems;

  return (
    <>
      <FilterMenu
        categoryFilters={categoryFilters}
        categoryItems={categoryItems}
        handleChange={handleChange}
      />
      {categoryItems.map((item) => {
        const terms = termsByCategory?.filter((term) => {
          return term?.category?.uid === item.uid;
        });

        const displayMenu = categoriesToShow.filter((cat) => {return cat.uid === item.uid}).length > 0

        return (
          <div key={item?.uid} style={{'display': displayMenu ? 'block' : 'none'}} >
            <CategoryMenu
              item={item}
              isMobileMenu={isMobileMenu}
              searchResults={searchResults}
              terms={terms}
            />
          </div>
        )
      })}
      {!!searchTerm && searchResults?.length === 0 && (
        <NoResults />
      )}
    </>
  );
}

export default React.memo(Menus);
