import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import CtaButton from 'components/CtaButton';
import CardContainer from './CardContainer';
import Icon from 'components/Icon';
import { useTheme } from '@material-ui/core/styles';
import ModalWithTrigger from 'components/ModalWithTrigger';
import PodcastForm from 'routes/Home/components/PodcastForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { dataLayerPush } from 'utils/dataLayer';
import ModalForm from './ModalForm';

const ModalWrapper = styled.div`
  button {
    background-color: ${theme('colors.ttRed')};
    border: 1px solid ${theme('colors.ttRed')};
    border-radius: 0.5625rem;
    color: ${theme('colors.white')};
    cursor: pointer;
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 1rem;
    font-weight: 600;
    min-width: 4rem;
    padding: 0.8125rem 2.25rem;
    text-transform: uppercase;

    &:hover {
      background-color: ${theme('colors.ttMuted')};
      color: ${theme('colors.white')};
    }

    svg {
      margin-left: 0.5rem;
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

const SyledIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

const LinkText = styled('div')`
  font-weight: 600;
  font-family: ${theme('fonts.robotoCondensed')};
  text-transform: uppercase;
  padding: 0.375rem 0.5rem 0.375rem 0;
  display: flex;
  align-items: center;
`;

const CtaButtonContainer = styled.span`
  a {
    margin-bottom: 0;
  }

  ${ifProp({$hover: 'expand'}, css`
    .MuiButtonBase-root {
      justify-content: start;
    }

    ${props => props.theme.breakpoints.up('md')} {
      opacity: 0;
    }

    ${CardContainer}:hover &{
      ${props => props.theme.breakpoints.up('md')} {
        opacity: 1;
      }
    }
  `)}
`;

const MODAL = 'open modal';
const BUTTON = 'button';

// setup const for mapping components in case others need to be
// created in the future; allows for more reusability
const modalComponents = {
  podcastIdeas: PodcastForm
}

CtaContainer.propTypes = {
  componentName: PropTypes.string,
  data: PropTypes.object,
  cardLink: PropTypes.string,
  hoverAnimation: PropTypes.string,
};

function CtaContainer({ modalForm, componentName, data, hoverAnimation, cardLink }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const buttonAction = get(data, 'buttonAction') || BUTTON;
  const { buttonCtaName, buttonIcon } = data;
  const ModalChildComponent = modalComponents[componentName] ?? ModalForm;

  const handleOpen = () => {
    dataLayerPush('Content card form modal opened');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {buttonAction === BUTTON && (
        <CtaButtonContainer $hover={hoverAnimation}>
          {!cardLink && (
            <CtaButton data={data} icon={buttonIcon} />
          )}
          {cardLink && isDesktop && (
            <LinkText>
              {data.buttonCtaName}
              <SyledIcon icon={buttonIcon} />
            </LinkText>
          )}
          {cardLink && !isDesktop && (
            <CtaButton data={data} icon={buttonIcon} />
          )}
        </CtaButtonContainer>
      )}
      {buttonAction === MODAL && (
        <ModalWrapper>
          <ModalWithTrigger
            ariaLabel='Feedback form'
            ariaDescribe='Form to provide feedback'
            handleOpen={handleOpen}
            handleClose={handleClose}
            open={open}
            padding='thin'
            triggerText={buttonCtaName}
            triggerType='button'
          >
            <ModalChildComponent modalForm={modalForm} onClose={handleClose} />
          </ModalWithTrigger>
        </ModalWrapper>
      )}
    </>
  );
}

export default React.memo(CtaContainer);
