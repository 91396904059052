import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { ifProp, theme } from 'styled-tools';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import definitionCategoryShape from 'shapes/definitionCategoryShape';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';
import definitionShape from 'shapes/definitionShape';
import ConceptAccordion from './ConceptAccordion';

const TermContainer = styled(Link)`
  align-items: center;
  cursor: pointer;
  color: ${theme('colors.black')};
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  text-decoration: none;

  &:hover {
    background: ${theme('colors.iconHoverBackgroundGray')};
    text-decoration: none;
  }

  ${ifProp('$active', css`
    background: ${theme('colors.ttRed')};
    color: ${theme('colors.white')};
    font-weight: 600;

    &:hover {
      background: ${theme('colors.ttRed')};
      color: ${theme('colors.white')};
    }
  `)}
`;

const Term = styled(Typography)`
  font-family: ${theme('typography.fontFamily')};
  font-size: 0.75rem;
  text-transform: uppercase;

  ${ifProp('$active', css`
    font-weight: 600;
  `)}
`;

CategoryMenu.propTypes = {
  isMobileMenu: PropTypes.bool,
  item: definitionCategoryShape,
  terms: PropTypes.arrayOf(definitionShape),
};

function CategoryMenu({ item, isMobileMenu = false, terms }) {
  const history = useHistory();
  const { activeTerm, setActiveTerm, searchTerm, toggleMobileMenu, expanded, setExpanded } = useKeyConceptsContext();
  const { definitionSlug } = useParams();
  const selectedTerm = `term-${definitionSlug} `;

  function handleClick(e, term) {
    e.preventDefault();
    setActiveTerm(term);

    if (isMobileMenu) {
      toggleMobileMenu(term);
      history.push(term.url);
    } else {
      history.push(term.url);
    }
  }

  function ScrollToElement(termSelector) {
    const targetElement = document.querySelector(`.${termSelector}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (selectedTerm) {
        ScrollToElement(selectedTerm);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [selectedTerm]);

  const searchResultsTerms = [];
  if (searchTerm) {
    const categoryTitle = item.title;

    terms.filter((term) => {
      const normalizeTerm = term.title.toLowerCase();
      const normalizeSearchTerm = searchTerm.toLowerCase();

      if (normalizeTerm.includes(normalizeSearchTerm)) {
        searchResultsTerms.push(term);
      }
    });
  }

  const termsToShow = !!searchTerm ? searchResultsTerms : terms;

  return (
    <ConceptAccordion
      icon='chevronDown'
      id={item?.uid}
      title={item?.title}
      $menuType='category'
      expandedCallback={setExpanded}
      initialExpanded={(expanded && expanded[item?.uid]) !== false}
    >
      {termsToShow.map((term) => {
        const active = term?.uid === activeTerm?.uid

        return (
          <TermContainer
            $active={active}
            key={term?.uid}
            href={term.url}
            onClick={(e) => handleClick(e, term)}
          >
            <Term className={`term-${term?.url?.split("/")?.slice(-1)}`} $active={active}>{term?.title}</Term>
          </TermContainer>
        )
      })}
    </ConceptAccordion>
  );
}

export default React.memo(CategoryMenu);
