import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import { useLocation } from 'react-router-dom';

const StyledForm = styled.form`
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
`

const StyledFullWidthSearchBar = styled.input`
  border: none;
  width: 100%;
  height: inherit;
  min-height: 3.75rem;
  font-size: 1rem;

  &:focus {
    border: 0;
    outline: none;
  }
`;

FullWidthSearchBar.propTypes = {
  onRequestSearch: PropTypes.func,
  setShowSearchBar: PropTypes.func,
  showSearchBar: PropTypes.bool,
};

function FullWidthSearchBar({ onRequestSearch, setShowSearchBar, showSearchBar, ...props }) {
  const [searchValue, setSearchValue] = useState('');
  const location = useLocation();

  const search = location.search;
  const queryParams = new URLSearchParams(search);
  const searchQuery = queryParams.get('q');

  function handleSubmit(e) {
    e.preventDefault();
    onRequestSearch(encodeURIComponent(searchValue));
    setSearchValue('')
    setShowSearchBar(false)
  }

  useMemo(() => {
    if (searchValue === '' && searchQuery && searchQuery !== '${value}') {
      setSearchValue(searchQuery);
    }
  }, [])

  return (
    <>
      <SearchIcon onClick={handleSubmit} />
      <StyledForm onSubmit={handleSubmit}>
        <StyledFullWidthSearchBar
          autoFocus={showSearchBar}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder='Search for anything'
          {...props}
        />
      </StyledForm>
    </>

  );
}

export default FullWidthSearchBar;
