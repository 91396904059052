import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

DateFormatter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.string,
};

function DateFormatter({ date, format = 'MMM D, YYYY', useCentralTime = false }) {
  const formattedDate = useCentralTime
    ? dayjs(date).tz('America/Chicago').format(format) 
    : dayjs(date).format(format); 
  return formattedDate;
}

export default React.memo(DateFormatter);
