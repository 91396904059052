import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import DOMPurify from 'isomorphic-dompurify';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import bearishArrow from 'assets/icons/conceptsIcons/icon-bearish.png';
import bullishArrow from 'assets/icons/conceptsIcons/icon-bullish.png';
import neutralArrow from 'assets/icons/conceptsIcons/icon-neutral.png';
import lowVolatility from 'assets/icons/conceptsIcons/icon-low-vol.png';
import highVolatility from 'assets/icons/conceptsIcons/icon-high-vol.png';
import { autoOptimizeImage, matchRuleShort } from 'utils/functions';

export const RichTextBox = styled(Box)`
  margin-top: 1em;
  margin-bottom: 1em;

  ${ifProp(
    '$disableMargin',
    css`
      margin: 0;

      & > p:first-child {
        margin-block-start: 0;
      }
    `
  )}

  .title {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }

  hr {
    ${(props) => props.theme.breakpoints.down('sm')} {
      display: none;
    }
  }

  h1 {
    span {
      color: ${theme('colors.ttRed')};
    }
  }

  .list-container {
    display: flex;

    ${(props) => props.theme.breakpoints.down('md')} {
      display: flex;
      flex-direction: column;
    }

    span {
      color: ${theme('colors.ttRed')};
      font-family: ${theme('typography.fontFamilySecondary')};
      font-size: 0.875rem;
      font-weight: 300;
      margin-left: 0.3125rem;
      text-transform: uppercase;
    }

    .custom-list {
      &:last-child {
        margin-left: 1.5rem;

        ${(props) => props.theme.breakpoints.down('md')} {
          margin-left: 0;
        }
      }
    }
  }

  .inline-icon {
    display: flex;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      margin-right: 0.75rem;
      max-height: 1.25rem;
      width: 1.5rem;
    }

    &.high-volatility {
      &::before {
        background-image: url(${highVolatility});
      }
    }

    &.low-volatility {
      &::before {
        background-image: url(${lowVolatility});
      }
    }

    &.bearish-assumption {
      &::before {
        background-image: url(${bearishArrow});
      }
    }

    &.bullish-assumption {
      &::before {
        background-image: url(${bullishArrow});
      }
    }

    &.neutral-assumption {
      &::before {
        background-image: url(${neutralArrow});
      }
    }
  }

  a {
    color: ${theme('colors.black')};

    &:hover {
      color: ${theme('colors.ttRed')};
    }
  }

  p {
    font-weight: 400;

    &:empty {
      display: none;
      color: #bada55;
    }
  }

  br:only-child {
    display: none;
  }

  img {
    //using important here to override the styles coming from CS
    max-width: 100% !important;

    ${(props) => props.theme.breakpoints.down('md')} {
      height: auto !important;
    }
  }

  ol {
    font-weight: 400;
  }

  ul {
    &.custom-list {
      list-style-type: none;
      padding: 0;

      li {
        align-items: center;
        display: flex;
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 0.625rem;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          background-image: url(https://images.contentstack.io/v3/assets/blt40263f25ec36953f/bltb2dd4af672708ae5/5fa302ca65bdd35303dff51c/icon-checkmark.svg);
          background-repeat: no-repeat;
          background-size: cover;
          content: '';
          display: inline-block;
          height: 1em;
          margin-right: 1.125rem;
          width: 1em;
        }
      }
    }

    &.red-list {
      li {
        &:before {
          background-image: url(https://images.contentstack.io/v3/assets/blt40263f25ec36953f/blt048a41c07355a4e9/5faae34742256d5ffdf4201e/icon-checkmark.svg);
          padding: 0.5rem;
          font-size: 0.5625rem;
        }
      }
    }

    &.ttbrand-list {
      list-style: none;
      margin: 0;
      padding-left: 0;

      li {
        display: flex;
        font-size: 1.25rem;
        font-weight: 500;

        &:before {
          content: '●';
          color: ${theme('colors.ttRed')};
          display: block;
          margin-right: 0.75rem;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    &:not(.ttbrand-list):not(.custom-list):not(.red-list) {
      list-style: none;
      padding-left: 2rem;

      li {
        padding-left: 0.375rem;
        position: relative;
        font-weight: 400;

        &:before {
          content: '•';
          color: ${theme('colors.ttRed')};
          font-weight: 800;
          font-size: 1.25rem;
          left: -10px;
          position: absolute;
          top: -2px;
        }
      }
    }
  }
`;

DOMPurify.setConfig({
  ADD_TAGS: ['iframe', 'embed', 'script'],
  ADD_ATTR: ['target'],
});

function RichTextField({ richText, component, ...props }) {
  // Regex captures the youtube video id, sans url or query parameters
  const youtubeRegex = /https:\/\/www.youtube.com\/embed\/([^"?> ]*)(\?[^"> ]*)?/g;

  // Replacement adds enablejsapi as the only parameter.
  const transformedRichText = richText?.replaceAll(
    youtubeRegex, "https://www.youtube.com/embed/$1?enablejsapi=1");

  const sanitizedHtml = DOMPurify.sanitize(transformedRichText);

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // if target='_blank' set rel='noopener'
    if (node?.target === '_blank') {
      node.setAttribute('rel', 'noopener');
    }

    // appending CS image optimization params if not already present
    if (node?.localName === 'img') {
      const imgSrc = node.getAttribute('src');

      if (imgSrc.includes('format=pjpg') || imgSrc.includes('auto=webp')) {
        return node;
      }

      // If image has an explicit width, we can optimize to that.
      const imgWidth = node.getAttribute('width');
      if (imgWidth) {
        node.setAttribute('src', autoOptimizeImage(imgSrc, true, imgWidth));
        return node;
      }

      const queryParams =
        '?format=pjpg&auto=webp&quality=50&width=1000&disable=upscale';
      const mobileQueryParams =
        '?format=pjpg&auto=webp&quality=50&width=345&disable=upscale';
      const responsiveSizes = '(max-width: 600px) 480px,1000px';
      const optimizedImgSrc = imgSrc + queryParams;
      const optimizedMobileImgSrc = imgSrc + mobileQueryParams;
      const imgSrcSet = `${optimizedMobileImgSrc} 345w, ${optimizedImgSrc} 1000w`;

      const altText = node.getAttribute('alt');

      // edge cases for the number images that should have 1:1 aspect-ratio
      const isNumberImage = matchRuleShort(altText, 'Number * in a red circle');

      if (isNumberImage) {
        node.setAttribute('style', 'aspect-ratio: 1 / 1');
      }
      node.setAttribute('src', optimizedImgSrc);
      node.setAttribute('srcset', imgSrcSet);
      node.setAttribute('sizes', responsiveSizes);
    }

    return node;
  });

  return (
    <RichTextBox
      as={component}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      {...props}
    />
  );
}

RichTextField.propTypes = {
  richText: PropTypes.string.isRequired,
  component: PropTypes.elementType,
};

export default RichTextField;
