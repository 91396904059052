import React from 'react';
import styled from 'styled-components';
import { Typography, Box } from '@material-ui/core';
import { prop } from 'styled-tools';
import Button from 'components/Button';
import { autoOptimizeImage } from 'utils/functions';

const FeaturedShowContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 2rem;
  padding: 0 10rem;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : 'none'};
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 2rem;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 1rem;
  }
`;

const ShowLogo = styled.img`
  object-fit: contain;
  width: 17rem;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 22rem;
    margin: 2.5rem 0;
  }
`;

const ContentContainer = styled.div`
  margin-top: 7rem;
  margin-bottom: 3rem;
  z-index: 1000;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

const Header = styled(Typography)`
  border-bottom: 3px solid ${prop('theme.colors.ttRed')};
  color: ${prop('theme.colors.white')};
  margin-bottom: 1rem;
  padding-bottom: .33rem;
  font-size: 1.25rem;
  font-weight: 700;
  display: inline-block;
`;

const Description = styled(Typography)`
  color: ${prop('theme.colors.white')};
`;

const TalentImageContainer = styled.div`
  display: flex;
  align-items: end;
  z-index: 1001;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    align-items: center;
    justify-content: center;
  }
`;

const TalentImage = styled.img`
  object-fit: contain;
  height: 35vw;
  max-height: 90%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 12rem;
  }
`;

const CtaButton = styled(Button)`
  margin-top: 2.5rem;
  border-radius: 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 1rem;
    width: 100%;
  }
`;

const ShowContent = styled(Box)`
  display: ${({ hasTalentImage }) => (hasTalentImage ? 'block' : 'flex')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: block;
  }
`;

const ShowImage = styled.img`
  width: 100%;
  object-fit: container;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 20rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(29, 25, 27, .85),
    rgba(255, 255, 255, 0) 69%
  );
`;

function FeaturedShow({ data, header }) {
  const { teaser, logoImage, talentImage, url, title, showOverlayColor, image, backgroundImage } = data;
  return (
    <FeaturedShowContainer
      backgroundColor={showOverlayColor}
      backgroundImage={autoOptimizeImage(backgroundImage.url, true, 3840)}
    >
      <ImageOverlay/>
      <ContentContainer>
        {header && (
          <Header variant='h2'>{header}</Header>
        )}
        <ShowContent hasTalentImage={talentImage}>
          {logoImage ? (
            <ShowLogo loading={'lazy'} src={logoImage.url} />
          ) : (
            <ShowImage loading={'lazy'} src={image.url} />
          )}
          <Box my={{ xs: 3, sm: 5, md: 0 }} ml={{ sm: 3, md: 0 }}>
            {teaser && (
              <Description>{teaser}</Description>
            )}
            <CtaButton href={url} variant='contained' color='primary'>
              Explore {title}
            </CtaButton>
          </Box>
        </ShowContent>
      </ContentContainer>
      {talentImage && (
        <TalentImageContainer>
          <TalentImage loading={'lazy'} src={autoOptimizeImage(talentImage.url)} />
        </TalentImageContainer>
      )}
    </FeaturedShowContainer>
  );
}

export default React.memo(FeaturedShow);
