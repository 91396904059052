import React, { useState, useEffect } from 'react';
import videoTwoBlockShape from 'shapes/components/videoTwoBlockShape';
import get from 'lodash.get';
import styled, { css } from 'styled-components';
import { switchProp, prop, theme } from 'styled-tools';
import { autoOptimizeImage } from 'utils/functions';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import getEventsQuery from '../../../graphql/queries/getEvents.graphql';
import EventContent from './components/EventContent';
import CtaButton from 'components/CtaButton';
import Container from '@material-ui/core/Container';
import DateFormatter from 'components/DateFormatter';

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
`;

const SubjectTitle = styled.p`
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: .875rem;
  font-weight: 700;
  border-bottom: 3px solid ${prop('theme.colors.ttRed')};
  font-family: ${theme('typography.fontFamilySecondary')};
`;

const Info = styled.div`
  padding: 5rem;
`;

const GridContainer = styled(Grid)`
  flex-direction: column;
  flex-wrap: nowrap;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

const InnerImageContainer = styled.div`
  position: relative;
  height: 100%;
`;

const DateDisplay = styled.div`
  z-index: 20;
  margin-left: -.5rem;
  font-size: 12vw;
  font-weight: 700;
  line-height: .6;
  position: absolute;
  top: auto;
  bottom: 2%;
  left: 0%;
  right: 0%;
  
  ${props => props.theme.breakpoints.down('md')} {
    font-size: 11vw;
  }
  
  ${props => props.theme.breakpoints.down('sm')} {
    margin-left: 0;
    font-size: 22vw;
  }
  
  ${props => props.theme.breakpoints.down('xs')} {
    bottom: 3.8%;
    text-align: center;
  }
  
  ${switchProp('$textColor', {
  Black: css`
      color: ${theme('colors.black')};
    `,
  Red: css`
      color: ${theme('colors.ttRed')};
    `,
  White: css`
      color: ${theme('colors.white')};
    `,
  Gray: css`
      color: ${theme('colors.gray')};
    `
})}
`;

const EventInfoContiner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 2rem;
  height: 100%;
  background-color: ${theme('colors.white')};
  
  h2 {
    font-size: 1.5rem;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    padding: 2.5rem 0rem;
  }
`;

VideoTwoBlock.propTypes = {
  data: videoTwoBlockShape,
};

function VideoTwoBlock({ data: propData }) {
  const { data: queryData, loading, error } = useQuery(getEventsQuery, {
    variables: {
      filters: {
        sortBy: 'event_date',
        sortDirection: 'ascending',
      },
    },
  });

  const [currentDate, setCurrentDate] = useState(new Date());
  const events = get(queryData, 'events.items', []);

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  const videoCover = get(propData, 'videoCover');
  const eventsInfo = get(propData, 'eventsInfo');
  const { altVideoText, video, videoThumbnail, webVideo } = videoCover;
  const videoExt = webVideo && webVideo.url.split('.').pop();
  const { subject, title, body, buttonCta, date, selectDateColor, useLatestEvent } = eventsInfo;
  const textColor = selectDateColor || 'White';
  const nextEvent = useLatestEvent ?
    events.find(event => event.eventDate && new Date(event.eventDate) > currentDate) || events.find(event => event.eventDate === null)
    : {
      title,
      eventDate: eventsInfo?.eventDate,
      eventDateDetails: eventsInfo?.eventDateDetails,
      eventDescription: body,
      eventTime: eventsInfo?.eventTime,
      eventPresenter: eventsInfo?.eventPresenter,
      buttonCta: buttonCta.map((button) => {
        return { buttonCta: [button] }
      }),
      eventSponsorLogo: eventsInfo?.eventSponsorLogo,
    };
  return (
    <div>
      <GridContainer container>
        <Grid item xs={12} sm={12} md={8}>
          <InnerImageContainer>
            {date && (
              <DateDisplay $textColor={textColor}>
                <DateFormatter date={date} format={'MM.DD.YY'} useCentralTime/>
              </DateDisplay>
            )}
            {webVideo ? (
              <Video
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                poster={autoOptimizeImage(videoThumbnail?.url)}
              >
                <source src={webVideo?.url} type={`video/${videoExt}`} />
              </Video>
            ) : (
              <Image src={autoOptimizeImage(video?.url)} alt={altVideoText} />
            )}
          </InnerImageContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {nextEvent ? (
            <EventInfoContiner>
              <Container>
                <SubjectTitle>{subject}</SubjectTitle>
              </Container>
              <EventContent
                overrideButtonStyle={'redSquareFilled'}
                overrideButtonJustify={'left'}
                eventItem={nextEvent}
                useLatestEvent={useLatestEvent}
              />
            </EventInfoContiner>
          ) : (
            <Info>
              <h1>{title}</h1>
              <p>{body}</p>
              <div>
                <CtaButton data={buttonCta} icon={get(buttonCta, 'buttonIcon')} />
              </div>
            </Info>
          )}
        </Grid>
      </GridContainer>
    </div>
  );
}

export default VideoTwoBlock;